body,
html {
  background-color: #f8fafc !important;
}

.rotating {
  animation: rotating 2s linear infinite;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-wrapper {
  transition: all 0.5s;
}

.nav-wrapper.active {
  opacity: 1 !important;
  visibility: visible;
}

.react-datepicker-wrapper {
  padding: 7px 10px !important;
  background: #f8fafc;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: bold;
  border: 2px solid var(--chakra-colors-blue-300) !important;
  width: fit-content !important;
}

.AddGoal .react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker__input-container input {
  background-color: #f8fafc;
  font-weight: bold;
}

.react-datepicker__header {
  background-color: #fff !important;
  padding: 10px 0 !important;
}

.react-datepicker__day--in-range {
  background-color: #49c883 !important;
  border-radius: 50% !important;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__navigation {
  height: 45px !important;
}

.react-datepicker {
  border: none !important;
  box-shadow: 9px 6px 16px rgb(0 0 0 / 12%);
  border-radius: 12px !important;
}

.react-datepicker__current-month {
  width: fit-content;
  margin-left: 15px;
  font-size: 0.8rem !important;
}

.react-datepicker__navigation--previous {
  margin-left: 180px;
  font-size: 10px !important;
}

.react-datepicker__day--in-selecting-range {
  background-color: #82f4b6 !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #49c883 !important;
  border-radius: 50% !important;
}

.react-datepicker__day--selected {
  background-color: #49c883 !important;
  border-radius: 50% !important;
}

.HistoryItem button {
  border-radius: 0px;
}

.HistoryItem button span {
  margin-right: 0px;
}

.entry-button {
  border: 2px solid;
  background: transparent !important;
  color: #49c883;
  border-color: #49c883;
}

.entry-button:hover {
  background: transparent !important;
}

.entry-button-active {
  background: #49c883 !important;
  color: #f9fbfc;
}

.entry-button-active:hover {
  color: #f9fbfc;
  background: #49c883;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  padding-bottom: 35px;
}

.entry-row {
  background: #fff;
  height: 74px;
  border-radius: 5px;
  box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.06);
  text-align: center;
  font-weight: 700;
}

.entry-delete {
  background: #fff;
  border-radius: 5px;
  text-align: center;
  font-weight: 700;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b7c5bb;
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

.blur .blur-card,
.blur {
  filter: blur(2px);
  pointer-events: none;
}

.ql-container {
  height: 450px !important;
}
